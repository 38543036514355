import project1 from "../images/saroj.jpg"

const projects = [
  {
    projectName: "MIT Inventory  System",
    imagePath: project1,
    description:
      "This is meat quality detection project system developer by saroj adhikari",
    path: "/project/mitproject",
  },
  {
    projectName: "Second Project",
    imagePath: project1,
    description:
      "This is meat quality detection project system developer by saroj adhikari",
    path: "/project/second-project",
  },
  {
    projectName: "3rd project",
    imagePath: project1,
    description:
      "This is meat quality detection project system developer by saroj adhikari",
    path: "/project/third-project",
  },
]
export default projects
